import React from "react"
import { Container, Row, Col } from "react-grid-system"
import { graphql } from "gatsby"
import AnimationLink from "../components/nav/AnimationLink"
import { Header1 } from "../elements"
import SEO from "../components/seo"
import { rhythm } from "../../config/typography"
import PropTypes from "prop-types"
import ProjectItem from "../components/works/ProjectItem"
import TransitionContext from "../context/TransitionContext"

const BlogIndexPage = ({ data, location, pageContext }) => {
  const { currentPage, numPages } = pageContext
  const { edges: posts } = data.allMdx
  const isFirst = currentPage === 1
  const isLast = currentPage === numPages
  const prevPage =
    currentPage - 1 === 1 ? `/blog` : `blog/${(currentPage - 1).toString()}`
  const nextPage = `/blog/${(currentPage + 1).toString()}`
  const cropDefault = data.cropDefault.childImageSharp.resize

  return (
    <>
      <SEO
        title="The Workshop. Creative resources. Design trends. Branding news."
        description="The Workshop. Creative resources. Design trends. Branding news."
        pathname={location.pathname}
        image={cropDefault}
      />
      <TransitionContext location={location}>
        <section className="section">
          <Container fluid className="pagemargintop pagemarginbtm innerwrap">
            <Row justify="center" align="center">
              <Col>
                <Header1 lineOne="The Workshop" lineTwo="Recent Posts" />
              </Col>
            </Row>

            <Row
              align="center"
              justify="center"
              gutterWidth={16}
              style={{ overflow: `hidden` }}
            >
              {posts.map((post, index) => (
                <Col md={4} sm={6} key={post.node.id}>
                  <ProjectItem
                    index={index}
                    post={post}
                    aria-label={` Visit post ${post.node.title} from PAKD Media`}
                    className="blog-image"
                    subpath={`/blog${post.node.fields.slug}`}
                  />
                </Col>
              ))}
            </Row>

            <Row align="center" justify="center">
              {!isFirst && (
                <AnimationLink
                  to={prevPage}
                  ariaLabel="Previous Posts"
                  rel="prev"
                  className="blogloader"
                >
                  ← Newer Posts
                </AnimationLink>
              )}
              {!isLast && (
                <AnimationLink
                  to={nextPage}
                  ariaLabel="Next Posts"
                  rel="next"
                  className="blogloader"
                >
                  Older Posts →
                </AnimationLink>
              )}
            </Row>
            <Row
              className="taglist"
              align="center"
              justify="center"
              style={{ paddingTop: `${rhythm(1)}` }}
            >
              <AnimationLink
                ariaLabel={`Browse all tags.'`}
                to={`/tags`}
                state={{ fromTagLink: true }}
                className="alltags"
              >
                Browse by Tag
              </AnimationLink>
            </Row>
          </Container>
        </section>
      </TransitionContext>
    </>
  )
}

export default BlogIndexPage

BlogIndexPage.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.shape({
    currentPage: PropTypes.number,
    numPages: PropTypes.number,
  }),
}
export const pageQuery = graphql`
  query BlogRollQuery($limit: Int!, $skip: Int!) {
    allMdx(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        frontmatter: { templateKey: { eq: "blog-post" }, draft: { eq: false } }
      }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            tags
            templateKey
            date(formatString: "MM.DD.YY")
            featuredpost
            bgcolor
            homehero: featuredimage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 100) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
    cropDefault: file(absolutePath: { regex: "/images/blog/WorkshopTile/" }) {
      childImageSharp {
        resize(width: 1024, height: 1024, cropFocus: CENTER) {
          src
          width
          height
        }
      }
    }
  }
`
